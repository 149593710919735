import React from "react";
import { useVerifyMutation } from "../../services";
import { Link, useSearchParams } from "react-router-dom";
import Logo from "../../assets/Logo/logo.png";

export const Verify = () => {
  const [verify, { isLoading, isSuccess, error }] = useVerifyMutation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gradient-to-r from-svijetloPlava1 to-blue-500">
      <div className="bg-white bg-opacity-50 p-8 rounded-lg shadow-lg max-w-md w-[95%] lg:w-full text-center">
        <div className="flex flex-col items-center">
          <img
            src={Logo}
            alt="Familia logo vertifikacija"
            className="w-[200px] mb-5 "
          />
          <h1 className="text-2xl font-poppins font-medium text-gray-800 mb-4">
            Vertifikuj svoj Profil
          </h1>
        </div>

        {error && (
          <div className="bg-red-100 text-red-700 p-2 mb-4 rounded-lg">
            {JSON.stringify(error)}
          </div>
        )}

        {isSuccess ? (
          <div>
            <p className="text-green-600 font-semibold mb-4">
              Vaš profil je uspješno aktiviran!
            </p>
            <Link
              to="/login"
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Prijavi se
            </Link>
          </div>
        ) : (
          <button
            className={`px-4 py-2 text-white rounded ${
              isLoading ? "bg-gray-500" : "bg-blue-600 hover:bg-blue-700"
            }`}
            onClick={() => verify(token)}
            disabled={isLoading}
          >
            {isLoading ? "Vertifikacija..." : "Vertifikuj"}
          </button>
        )}
      </div>
    </div>
  );
};

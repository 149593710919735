import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Logo from "../../assets/Logo/logo.png";
import { destroyAllTokens } from "../../utils";
import ProfileIcon from "../../assets/Logo/profileIcon.png";

const navLinks = (isLoggedIn) => [
  { path: "/", text: "Početna" },
  { path: "/o-nama", text: "O Nama" },
  { path: "/whyus", text: "Benefiti" },
  {
    path: "/usluge",
    text: "Usluge",
    dropdownItems: [
      { path: "/smjestaj", text: "Smještaj" },
      { path: "/opstamedicinskanjega", text: "Opšta i medicinska njega" },
      { path: "/rehabilitacija", text: "Rehabilitacija" },
      { path: "https://mozdaniudar.ba/", text: "Moždani udar" },
      { path: "/robotskaRehab", text: "Robotska rehab" },
      { path: "/dom", text: "Ambulantna rehabilitacija" },
      { path: "/vrRehab", text: "VR rehab" },
    ],
  },
  { path: "/galerija", text: "Galerija" },
  { path: "/sucess-stories", text: "Uspješne priče" },
  {
    path: "/kontakt",
    text: "Kontakt",
    dropdownItems: [
      { path: "/kontakt", text: "Kontakt" },
      { path: "/posjete", text: "Posjete" },
      { path: "/konsultacije", text: "Konsultacije" },
      {
        path: "https://www.linkedin.com/company/domfamilia/jobs/",
        text: "Otvorene pozicije",
      },
    ],
  },
  isLoggedIn
    ? {
        path: "/forme",
        text: "Forme",
        dropdownItems: [
          { path: "/servisi", text: "Servisi" },
          { path: "https://form.jotform.com/241983126464057", text: "Bartel skala" },
        ],
      }
    : {},
  { path: "http://portalfamilia.access.ly:8080/", text: "Portal" },
  {
    path: "/nalog",
    text: "Prijava",
    dropdownItems: isLoggedIn
      ? [
          {
            path: "/",
            text: "Logout",
            onClick: () => destroyAllTokens(),
          },
        ]
      : [
          { path: "/login", text: "Login" },
          { path: "/register", text: "Register" },
        ],
    icon: ProfileIcon,
  },
].filter((obj) => Object.keys(obj).length > 0);

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isUslugeDropdownOpen, setUslugeDropdownOpen] = useState(false);
  const [isKontaktDropdownOpen, setKontaktDropdownOpen] = useState(false);
  const [isNalogDropdownOpen, setNalogDropdownOpen] = useState(false);
  const [isFormeDropdownOpen, setFormeDropdownOpen] = useState(false);
  const location = useLocation();
  const [, isLoggedIn] = useAuth();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setUslugeDropdownOpen(false);
    setKontaktDropdownOpen(false);
    setNalogDropdownOpen(false);
    setFormeDropdownOpen(false);
  };

  const openDropdown = (setDropdownOpen) => {
    setDropdownOpen(true);
  };

  const closeDropdown = (setDropdownOpen) => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    closeMenu();
  }, [location.pathname]);

  return (
    <nav className="fixed top-0 w-full z-10 bg-white shadow-2xl">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
        <Link to="/" className="flex items-center" onClick={closeMenu}>
          <img src={Logo} className="h-[100px] py-3 ml-7" alt="Logo Familia" />
        </Link>
        <div className="p-6 lg:hidden" id="menuToggle">
          <input
            id="checkbox"
            type="checkbox"
            checked={isMenuOpen}
            onChange={toggleMenu}
          />
          <label className="toggle" htmlFor="checkbox">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </label>
        </div>
        <div
          className={`w-full lg:block lg:w-auto transition-all duration-500 ease-in-out ${
            isMenuOpen
              ? "opacity-100 max-h-screen lg:max-h-full"
              : "opacity-0 max-h-0 lg:opacity-100 lg:max-h-full overflow-hidden"
          }`}
          id="navbar-dropdown"
        >
          <ul className="flex flex-col font-light lg:h-[40px] lg:items-center p-4 lg:p-0 mt-4 border rounded-lg lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0">
            {navLinks(isLoggedIn).map((link, index) => (
              <li
                key={index}
                onMouseEnter={
                  link.dropdownItems
                    ? () =>
                        openDropdown(
                          link.text === "Usluge"
                            ? setUslugeDropdownOpen
                            : link.text === "Kontakt"
                            ? setKontaktDropdownOpen
                            : link.text === "Prijava"
                            ? setNalogDropdownOpen
                            : setFormeDropdownOpen
                        )
                    : null
                }
                onMouseLeave={
                  link.dropdownItems
                    ? () =>
                        closeDropdown(
                          link.text === "Usluge"
                            ? setUslugeDropdownOpen
                            : link.text === "Kontakt"
                            ? setKontaktDropdownOpen
                            : link.text === "Prijava"
                            ? setNalogDropdownOpen
                            : setFormeDropdownOpen
                        )
                    : null
                }
              >
                {link.dropdownItems ? (
                  <div>
                    <button
                      className={`flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-700 font-semibold lg:hover:bg-transparent lg:border-0 lg:p-0 lg:w-auto ${
                        location.pathname === link.path
                          ? "underline underline-offset-8 decoration-4"
                          : ""
                      }`}
                    >
                      {link.icon && (
                        <img src={link.icon} alt={link.text} className="w-6 h-6 mr-2" />
                      )}
                      {link.text}
                      <svg
                        className="w-2.5 h-2.5 ml-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    {(isUslugeDropdownOpen && link.text === "Usluge") ||
                    (isKontaktDropdownOpen && link.text === "Kontakt") ||
                    (isNalogDropdownOpen && link.text === "Prijava") ||
                    (isFormeDropdownOpen && link.text === "Forme") ? (
                      <div
                        id="dropdownNavbar"
                        className="lg:absolute lg:z-10 px-5 font-normal divide-y divide-gray-100 rounded-lg w-full lg:w-52 bg-white backdrop-filter backdrop-blur-lg transition-all duration-300 ease-in-out transform scale-100 opacity-1"
                      >
                        <ul
                          className="py-2 text-sm text-gray-700"
                          aria-labelledby="dropdownLargeButton"
                        >
                          {link.dropdownItems.map((item, index) => (
                            <li key={index}>
                              <Link
                                to={item.path}
                                className={`block px-4 py-2 hover:bg-gray-200 ${
                                  location.pathname === item.path
                                    ? "underline underline-offset-8 decoration-2 decoration-Narandzasta"
                                    : ""
                                }`}
                                {...(item.path.startsWith("http") && {
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                })}
                                onClick={item.onClick || closeMenu}
                              >
                                {item.text}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <Link
                    to={link.path}
                    className={`block py-2 pl-3 pr-4 text-gray-700 font-semibold rounded hover:text-blue-900 hover:underline lg:border-0 lg:p-0 ${
                      location.pathname === link.path
                        ? "underline underline-offset-8 decoration-2 decoration-Narandzasta"
                        : ""
                    }`}
                    {...(link.path.startsWith("http") && {
                      target: "_blank",
                      rel: "noopener noreferrer",
                    })}
                    onClick={link.onClick || closeMenu}
                  >
                    {link.icon && (
                      <img src={link.icon} alt={link.text} className="w-6 h-6 mr-2" />
                    )}
                    {link.text}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

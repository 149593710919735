import React from "react";
import Image1 from "../../assets/images/Sist 4.png";
import { Link } from "react-router-dom";
import Avatar2 from "../../assets/AvatarIcons/hospitalist.png";
import Avatar3 from "../../assets/AvatarIcons/neurosurgeon.png";
import Avatar4 from "../../assets/AvatarIcons/nurse.png";
import Avatar5 from "../../assets/AvatarIcons/oncologist.png";
import Avatar6 from "../../assets/AvatarIcons/orthopedist.png";
import Avatar7 from "../../assets/AvatarIcons/pharmacist.png";
import Avatar8 from "../../assets/AvatarIcons/kardiolog.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const avatarData = [
  {
    id: 1,
    image: Avatar4,
    title: "Kvalificirane i obučene medicinske sestre",
    desc: "Tim medicinskih sestara i njegovatelja",
  },
  {
    id: 2,
    image: Avatar5,
    title: "Specijalista infektolog/Intnzivista",
    desc: "Specijalista infektivnih bolesti",
  },
  {
    id: 3,
    image: Avatar3,
    title: "Neuropsihijatar",
    desc: "Specijalista neuropsihijatar",
  },
  {
    id: 4,
    image: Avatar6,
    title: "Fizioterapeuti",
    desc: "Specijalista fizijatar i tim fizioterapeuta",
  },
  {
    id: 5,
    image: Avatar7,
    title: "Magistar Farmacije",
    desc: "Voditelj odjela za upravljanje lijekovima.",
  },
  {
    id: 6,
    image: Avatar8,
    title: "Kardiolog",
    desc: "Specijalista kardiolog",
  },
  {
    id: 7,
    image: Avatar2,
    title: "Voditelj jedinice",
    desc: "Pojačane medicinske njege",
  },
];

const Njega = () => {
  return (
    <HelmetProvider>
      <div className="flex flex-col mt-20 sm:mt-2 items-center justify-center bg-gray-100 min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Familia O Nama</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="w-full ">
          <div className="relative overflow-hidden">
            <img
              className="w-full h-[550px]  md:h-[1200px] object-cover object-left"
              src={Image1}
              alt="About Us"
            />
            <div className="absolute inset-0 bg-black opacity-75"></div>
            <div className="absolute inset-0 flex items-center flex-col justify-center text-white">
              <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl font-bold mb-8 text-center">
                O Nama
              </h1>
              <h2 className="text-xl md:text-2xl lg:text-3xl  mb-8 text-Narandzasta text-center sm:text-start">
                Ustanova Familia: Sveobuhvatna njega i rehabilitacija za starije
                osobe
              </h2>
              <p className="text-md text-center md:text-center sm:text-lg md:text-lg lg:text-2xl leading-relaxed px-5 sm:px-10 lg:px-36  xl:px-52  font-extralight mb-8 text-krem">
                Ustanova za medicinsku njegu i rehabilitaciju Familia uspješno
                radi od 2019 godine sa fokusom na opštu i medicinsku njegu te
                rehabilitaciju za osobe treće životne dobi. Ustanova Familia
                nudi smještaj, opštu i medicinsku njegu, rehabilitaciju i dnevni
                boravak za starije osobe, te uz pomoć stručnog osoblja i
                multidisciplinarnog tima, pruža starijim pacijentima
                sveobuhvatnu skrb i podršku za bolji kvalitet života.
              </p>
            </div>
          </div>
          <div className="bg-krem p-8 md:p-16 lg:p-24">
            <div className="md:w-[90%] lg:w-[85%] 2xl:w-[80%] mx-auto">
              <div className="mb-5">
                <h2 className="text-2xl text-Narandzasta">Cilj Boravka</h2>
                <div className="custom-bullets px-2 mt-5 sm:px-10 font-normal">
                  <p className="text-TamnoPlava text-base lg:text-lg">
                    Cilj boravka u našoj ustanovi je pomoći osobama koji se u
                    procesu starenja suočavaju sa različitim izazovima koji mogu
                    značajno utjecati na sposobnost kretanja, samostalnosti i
                    kvalitet života. Nakon kritičnih događaja poput moždanog
                    udara, velikih ortopedskih zahvata ili neurodegenerativnih
                    bolesti, ključno je pravilno upravljati opštom/medicinskom
                    njegom i rehabilitacijom, kako bi se osiguralo što bolje
                    fizičko i mentalno zdravlje.
                  </p>
                </div>
              </div>
              <div className="mb-5">
                <h2 className="text-2xl text-Narandzasta">
                  U slučaju hitnosti - mi reagujemo odmah.
                </h2>
                <div className="custom-bullets px-2 mt-5 sm:px-10 font-normal">
                  <p className="text-TamnoPlava text-base lg:text-lg">
                    Naša usluga eliminira čekanje u redu jer stručni tim odmah
                    procenjuje stanje i potrebe pacijenta omogućavajući im brz
                    početak rehabilitacije.
                  </p>
                </div>
              </div>
              <div className="mb-5">
                <h2 className="text-2xl text-Narandzasta">Usluge i Podrška</h2>
                <div className="custom-bullets px-2 mt-5 sm:px-10 font-normal">
                  <p className="text-TamnoPlava text-base lg:text-lg">
                    Ustanova Familia posvećena je pružanju širokog spektra
                    usluga kako bi zadovoljila potrebe svojih korisnika. Naš tim
                    stručnjaka nudi personaliziran plan njege i rehabilitacije
                    koji uključuju fizioterapiju, radnu terapiju, logopedsku
                    terapiju, te psihološku podršku. Uz individualizirani
                    pristup, svaki korisnik dobija priliku da povrati
                    funkcionalnost i postigne što veći stepen samostalnosti.
                    Naša misija je osigurati da svaki korisnik dobije pažnju i
                    podršku koju zaslužuje, omogućavajući im dostojanstven i
                    kvalitetan život.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-14 ">
              <Link
                to="/kontakt"
                className="w-36 lg:w-48 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-medium text-base lg:text-xl tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
              >
                Kontakt
              </Link>
            </div>
          </div>

          <div className="p-8 md:py-16 lg:py-12 bg-gray-200">
            <h1 className="text-5xl lg:text-6xl font-semibold text-TamnoPlava text-center mb-3">
              O našem timu
            </h1>
            <h2 className="text-2xl lg:text-3xl font-semibold text-Narandzasta font-poppins text-center mb-10">
              Svi eksperti na jednom mjestu
            </h2>
            <div className="flex flex-wrap justify-center">
              {avatarData.map((avatar) => (
                <div
                  key={avatar.id}
                  className="p-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                >
                  <img
                    src={avatar.image}
                    alt={avatar.title}
                    className="w-[200px] mx-auto mb-4"
                  />
                  <h3 className="text-xl text-center font-semibold font-poppins">
                    {avatar.title}
                  </h3>
                  <p className="text-center text-base lg:text-lg text-gray-700">
                    {avatar.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Njega;
